<template>
  <header
    ref="header"
    class="grid-container shadow-header top-0 left-0 w-full z-50 bg-white sticky"
  >
    <div class="flex flex-wrap gap-y-0.75 gap-x-1.5 lg:gap-y-4 lg:gap-x-8 py-4">
      <client-only>
        <template #fallback />
        <Button
          v-if="isLoggedIn"
          class="peer overflow-hidden order-2 flex-1"
          :ui="{ text: 'text-3.5 lg:text-4', gap: '', rounded: 'rounded-2.5' }"
          @click="handleOpenPopup"
        >
          <span class="lg:max-w-80 text-ellipsis whitespace-nowrap overflow-hidden">{{ addressHandler ?? addressText }}</span>
          <!-- <span
            v-if="!addressHandler"
            class="shrink-0"
          >, {{ addressText[1] }}</span> -->
        </Button>
      </client-only>
      <NuxtLink
        to="/"
        class="self-center peer-[_]:order-1 order-2 lg:order-first"
      >
        <Logo class="text-firmennyy hover:text-firmennyy-bright transition-color max-lg:hidden h-9 w-fit" />
        <img
          class="w-11 lg:w-60.5 lg:hidden"
          src="/imgs/logo-m.svg"
          alt=""
        >
      </NuxtLink>
      <HeaderSearch />
      <ProfileButton class="min-w-11.5 lg:min-w-33 flex order-6 peer-[_]:order-3 lg:order-3" />

      <div class="h-0 lg:h-px w-full bg-plashka-seryy order-4 hidden peer-[_]:block lg:block"></div>

      <HeaderNav
        :items="navItems"
        class="max-lg:hidden order-4"
      />
      <Teleport to="body">
        <TransitionOpacity>
          <LazyHeaderBurgerMenu
            v-if="burgerMenuShow"
            :items="navItems"
            class="lg:hidden"
            @close="burgerMenuShow = false"
          ></LazyHeaderBurgerMenu>
        </TransitionOpacity>
      </Teleport>
      <button
        class="flex lg:hidden rounded-2.5 bg-transparent text-firmennyy p-2.5 order-1 peer-[_]:order-4 max-lg:peer-[_]:bg-plashka-seryy max-lg:peer-[_]:text-seryy"
        @click="burgerMenuShow = true"
      >
        <span class="i-custom:hamburger-menu text-6"></span>
      </button>
      <NuxtLink
        to="/wish"
        class="flex items-center justify-center relative bg-plashka-seryy rounded-2.5 text-#718997 shrink-0 w-11.5 lg:w-13 order-4 max-lg:peer-[_]:order-6 lg:order-last hover:bg-seryy-hover transition-colors"
      >
        <span class="i-custom:heart-fill-2 text-5.5"></span>
        <client-only>
          <template #fallback />
          <span
            v-if="wishTotalCount"
            class="w-5 h-5 text-3.5 flex justify-center items-center rounded-2.5 p-1 bg-white absolute top-1 right-0.5"
          >{{ wishTotalCount }}</span>
        </client-only>
      </NuxtLink>
      <Button
        :tag="NuxtLink"
        to="/cart"
        class="relative ml-auto max-lg:p-3 max-lg:bg-plashka-seryy max-lg:text-#718997 lg:min-w-80 w-11.5 whitespace-nowrap order-5 peer-[_]:order-7"
      >
        <template #prefix>
          <span class="text-5.5 shrink-0 i-custom:basket"></span>
        </template>
        <client-only>
          <template #fallback>
            <span class="max-lg:hidden">Корзина</span>
          </template>
          <span class="max-lg:hidden">{{ cartBtnLabel }}</span>
          <span
            v-if="cartTotalCount"
            class="w-5 h-5 text-3.5 flex justify-center items-center rounded-2.5 p-1 bg-white absolute top-1 right-0.5 lg:hidden"
          >{{ cartTotalCount }}</span>
        </client-only>
      </Button>
    </div>
  </header>
</template>

<script setup lang="ts">
import { NuxtLink } from '#components';
import { useCart } from '~/composables/cart/useCart';
import { useCategory } from '~/composables/category/useCategory';
import { useAddingAddressPopup } from '~/composables/popups/useAddingAddressPopup';
import { useRestaurant } from '~/composables/restaurant/useRestaurant';
import { useUserInfo } from '~/composables/user/useUserInfo';
import { useUserAuth } from '~/composables/user/useUserAuth';
import { useWish } from '~/composables/wish/useWish';

import type { Nav } from './type.ts';
import { useAuthPopup } from '~/composables/popups/useAuthPopup';

const { $priceFormat } = useNuxtApp();
const { cartTotalCount, cartTotalPrice } = useCart();
const { openPopup } = useAddingAddressPopup();
const { userCurrentAddress } = useUserInfo();
const { isLoggedIn } = useUserAuth();
const { openModal: openAuthPopup } = useAuthPopup();

const { wishTotalCount } = useWish();

const cartBtnLabel = computed(() => {
  return cartTotalPrice.value
    ? `В корзине ${cartTotalCount.value} шт  за ${$priceFormat({ amount: cartTotalPrice.value })}₽`
    : 'Корзина';
});

const handleOpenPopup = async () => {
  if (!isLoggedIn.value) {
    openAuthPopup();
  } else {
    openPopup();
  }
};

const addressHandler = computed(() => {
  if (userCurrentAddress.value) { return null; }
  if (!isLoggedIn.value) {
    return 'Войдите, чтобы добавить адрес доставки';
  }
  return 'Укажите адрес доставки';
});

const addressText = computed(() => {
  let address = userCurrentAddress.value?.address;
  // @ts-ignore
  if (!address && userCurrentAddress.value?.fias_id?.label) {
    // @ts-ignore
    address = userCurrentAddress.value.fias_id.label;
  }

  if (address) {
    const array = address.split(', ');
    const lastElement = array.splice(-1);
    const addressText = [array.join(', '), ...lastElement];
    let addressTrueText = addressText[0] + ' ' + addressText[1];
    if (userCurrentAddress.value?.flat) {
      addressTrueText = addressTrueText + `, КВ ${userCurrentAddress.value?.flat}`;
    }
    // if (userCurrentAddress.value?.doorphone) {
    //   addressTrueText = addressTrueText + `, Домофон ${userCurrentAddress.value?.doorphone}`;
    // }
    // if (userCurrentAddress.value?.entrance) {
    //   addressTrueText = addressTrueText + `, подъезд ${userCurrentAddress.value?.entrance}`;
    // }
    return addressTrueText;
  }

  return [];
});

const { categoryList } = useCategory();
const { initialRestrauntList } = useRestaurant();

const navItems = computed(() => {
  const object: Nav = {
    category: {
      link: {
        title: 'Категории',
        to: '/categories',
        titleAll: 'Все категории'
      },
      items: []
    },
    kuhni: {
      link: {
        title: 'Кухня',
        to: '/kuhni',
        titleAll: 'Все кухни'
      },
      items: []
    },
    restaurants: {
      link: {
        title: 'Заведения',
        to: '/restaurants',
        titleAll: 'Все заведения'
      },
      items: initialRestrauntList.value.list.map((a) => { return { img_path: a.img_path, title: a.title, slug: a.slug }; })
    },
    products: {
      link: {
        title: 'Блюда',
        to: '/bluda',
        titleAll: 'Все блюда'
      },
      items: []
    }
  };

  categoryList.value.forEach((a) => {
    if (a.type.description === 'Категория') { object.category.items?.push({ img_path: a.img_path, title: a.title, slug: a.slug }); }
    if (a.type.description === 'Кухня') { object.kuhni.items?.push({ img_path: a.img_path, title: a.title, slug: a.slug }); }
    if (a.type.description === 'Блюдо') { object.products.items?.push({ img_path: a.img_path, title: a.title, slug: a.slug }); }
  });

  return object;
});

const burgerMenuShow = ref(false);

const header = ref();
const { height } = useElementSize(header);
const headerHeight = useState('header-height');

watch(height, (newValue) => headerHeight.value = newValue);
</script>
