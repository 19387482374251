import type { FetchError } from 'ofetch';
import type { ServerResponseCommonType } from '~/types/commons';
import type { BySlugRestaurantType, RestaurantListType, RestaurantRequestQueryType, RestaurantType } from '~/composables/restaurant/types/RestaurantType';
import { restaurantStore as store } from '~/stores/restaurant-store';
import type { Ref } from 'vue';

type UseRestaurantType = {
	getRestaurantList:(options?: RestaurantRequestQueryType, initial?: boolean) => Promise<RestaurantListType | null>;
	getRestaurantBySlug:(slug: string) => Promise<BySlugRestaurantType | null>;
	restaurantList:Ref<RestaurantListType>;
  initialRestrauntList: Ref<RestaurantListType>;
}

export function useRestaurant ():UseRestaurantType {
  const { $api } = useNuxtApp();
  const restaurantStore = store();
  const { restaurantList, initialRestrauntList } = storeToRefs(restaurantStore);

  async function getRestaurantList (options?: RestaurantRequestQueryType, initial?: boolean):Promise<RestaurantListType | null> {
    const res = await $api<ServerResponseCommonType<RestaurantListType>>('restaurant/getList', {
      method: 'GET',
      query: {
        ...(options?.categories_ids && options.categories_ids.reduce((acc, id, index) => {
          // @ts-ignore
          acc[`categories_ids[${index}]`] = id;
          return acc;
        }, {} as Record<string, string>))
      }
    })
	    .catch((err:FetchError) => {
        throw err;
      });

    if (res && res.message === 'Success') {
      console.log(res.data);
	    restaurantStore.setRestaurantList(res.data);
      if (initial) {
        restaurantStore.setRestaurantListInitial(res.data);
      }
      return res.data;
    }

    return null;
  }

  async function getRestaurantBySlug (slug:string):Promise<BySlugRestaurantType | null> {
    const res = await $api<ServerResponseCommonType<BySlugRestaurantType>>('restaurant/getBySlug', {
      query: { slug }
    }).catch((err:FetchError) => {
      throw err;
    });

    if (res && res.message === 'Success') {
      return res.data;
    }

    return null;
  }

  return {
	  restaurantList,
	  getRestaurantBySlug,
	  getRestaurantList,
    initialRestrauntList
  };
}
