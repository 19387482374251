<template>
  <noscript>
    <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5X3XQW46"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>
  </noscript>
  <noscript><div><img src="https://mc.yandex.ru/watch/97867007" style="position:absolute; left:-9999px;" alt="" /></div></noscript>
  <section class="flex min-h-screen flex-col justify-start">
    <Header v-bind="(headerAttrs as object)" />
    <NuxtLoadingIndicator color="#FFC115" />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>

    <Footer class="mt-10 lg:mt-22.5" />
    <ModalAppWrapper />
  </section>
</template>
<script lang="ts" setup>
import { seoHandlers } from '~/utils';
import ModalAppWrapper from '~/components/Modal/ModalAppWrapper.vue';
import { useHead } from '#imports';
import { UseSiteSettings } from './composables/site-settings/useSiteSettings';
import { useRestaurant } from './composables/restaurant/useRestaurant';

const { setSEOTitles } = seoHandlers();
const { getSiteSettings } = UseSiteSettings();
const { getRestaurantList } = useRestaurant();

setSEOTitles({
  seoTitle: 'Доставка еды  в Южно-Сахалинске',
  seoDescription: 'Доставка еды  в Южно-Сахалинске'
});

await useAsyncData('initial-rests', () => getRestaurantList(undefined, true));

const { data } = await useAsyncData('siteSettings', () => getSiteSettings(), {
  immediate: true
});

useHead({
  meta: [
    // { name: 'viewport', content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no' }
  ],
  link: [
    { rel: 'icon', type: 'image/x-icon', href: '/fav/favicon.ico' },
    { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/fav/favicon-16x16.png' },
    { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/fav/favicon-32x32.png' },
    { rel: 'icon', type: 'image/png', sizes: '48x48', href: '/fav/favicon-48x48.png' },
    { rel: 'apple-touch-icon', type: 'image/png', sizes: '180x180', href: '/fav/apple-touch-icon.png' },
    { rel: 'icon', type: 'image/png', sizes: '192x192', href: '/fav/android-chrome-192x192' },
    { rel: 'icon', type: 'image/png', sizes: '512x512', href: '/fav/android-chrome-512x512' }
  ],
  htmlAttrs: { lang: 'ru' }
});
// @ts-ignore
// @ts-ignore

/* добавить типы для кастоных мета данных страниц */
const route = useRoute();
const headerAttrs = computed(() => route.meta?.headerAttrs ?? {});
</script>
